<script setup>
import { getSuitablePosition, getSuitableOrigins, parseOrigins, getScrollParent } from "./menu.utils";

const props = defineProps({
  modelValue: { default: false },
  origins: { default: "left top" },
  positionAlign: { default: "vertical" },
});
const emit = defineEmits(["update:modelValue"]);
const attrs = useAttrs();

const isOpen = ref(false);
const isVisible = ref(false);
const isAnimation = ref(false);

const cssStartX = ref(0);
const cssStartY = ref(0);
const cssOriginsX = ref("left");
const cssOriginsY = ref("top");

const popupRef = ref(null);
const activatorRef = ref(null);

watchEffect(() => emit("update:modelValue", isOpen.value));

watch(
  () => isOpen.value,
  () => {
    if (isOpen.value) handleOnOpen();
    else handleOnClose();
  }
);

function open() {
  isOpen.value = !isOpen.value;
}

function close(e) {
  isOpen.value = false;
}

function handleOnBlur(event) {
  if (!event.currentTarget.contains(event.relatedTarget)) close();
}

function handleOnOpen() {
  updateVisibleState();
  isVisible.value = true;
  setTimeout(() => (isAnimation.value = true));
  setTimeout(() => popupRef.value?.focus()); //{ focusVisible: false }));
}

function handleOnClose() {
  isAnimation.value = false;
}

function updateVisibleState() {
  setLocation();
}

function setLocation() {
  const origins = getSuitableOrigins({
    activatorEl: activatorRef.value,
    popupEl: popupRef.value,
    defaultOrigins: parseOrigins(props.origins),
  });

  //set animation direction
  cssOriginsX.value = origins.x;
  cssOriginsY.value = origins.y;

  const position = getSuitablePosition({
    activatorEl: activatorRef.value,
    popupEl: popupRef.value,
    origins,
    positionAlign: props.positionAlign
  });

  cssStartX.value = toPixels(position.left);
  cssStartY.value = toPixels(position.top);
}

function handleOnTransitionEnd() {
  isVisible.value = isOpen.value;
}

function handleOnTransitionStart() {}

onMounted(() => {});
</script>
<template>
  <div class="v-menu-wrapper">
    <div class="v-menu" ref="activatorRef">
      <slot name="activator" :on="open" :state="isOpen" :close="close"></slot>
    </div>

    <teleport to="body">
      <div
        ref="popupRef"
        class="popup hidden"
        :class="{
          visible: isVisible,
          animate: isAnimation,
        }"
        :key="isVisible"
        tabindex="1"
        @transitionstart="handleOnTransitionStart"
        @transitionend="handleOnTransitionEnd"
        @blur="handleOnBlur"
      >
        <slot :close="close"></slot>
      </div>
    </teleport>

    <teleport to="body">
      <div
        class="backdrop hidden"
        :class="{
          visible: isVisible,
          animate: isAnimation,
        }"
        @click.stop="close()"
      ></div>
    </teleport>
  </div>
</template>
<style scoped>
.v-menu-wrapper {
  /* height: inherit; */
  display: inline;
}
.v-menu {
  display: inline-flex;
  /* position: relative; */
  align-items: center;
  justify-content: center;
  /* height: inherit; */
}
.hidden {
  visibility: hidden;
  opacity: 0;
}
.visible {
  visibility: visible;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;

  padding: 8px 0px;
  border-radius: 12px;
  /* box-shadow: 5px 5px 5px -1px rgb(0 0 0 / 16%); */
  box-shadow: 0px 4px 11px 0px var(--menu-shadow, rgb(0 0 0 / 16%)); 
  /* box-shadow: 5px 5px 5px -1px rgb(0 0 0 / 16%); */
  /* background-color: white; */
  color: var(--menu-text);
  background-color: var(--menu-bg, #fff);

  transition: transform 0.2s cubic-bezier(0.2, 0, 0.2, 1),
    opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
  transform-origin: v-bind(cssOriginsY) v-bind(cssOriginsX);
  transform: translate3d(v-bind(cssStartX), v-bind(cssStartY), 0) scale(0.5);

  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(90vh - 200px);
  outline: none;
  /* padding-bottom: 20px; */
}

.popup.animate {
  z-index: 999999;
  transform: translate3d(v-bind(cssStartX), v-bind(cssStartY), 0) scale(1);
  opacity: 1;
  /* box-shadow: 5px 5px 5px -1px rgb(0 0 0 / 16%); */
}

.backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #202124;
  /* background-color: red; */
  transition: opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
  z-index: 9999;
}
.backdrop.animate {
  /* opacity: 0; */
  /* opacity: 0.3; */
}
</style>
